<template>
    <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 206px)">
        <div class="col-12">
            <el-table :suffix-icon="Folder" :data="carpetas.filter(data => !search || data.nombre.toLowerCase().includes(search.toLowerCase()))" height="770" style="width: 100%" @row-click="goDetail">
                <el-table-column sortable prop="nombre" label="Carpetas" width="200">
                    <template slot="header">
                        <i class="icon-folder-empty" />
                        <span class="pl-1 pr-3 py-1 f-14 text-center">
                            Carpetas
                        </span>
                    </template>
                    <template slot-scope="scope">
                        <span class="pl-1 pr-3 py-1 f-14 text-center">
                            {{ scope.row.nombre }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column sortable prop="created_at" label="Creacion" width="200">
                    <template slot-scope="scope">
                        <span class="pl-1 pr-3 py-1 f-14 text-center">
                            {{ formatearFecha(scope.row.created_at) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="cant_archivos" label="Archivos" width="200" />
                <el-table-column sortable prop="fecha_ultimo_pago" label="privacidad" width="200">
                    <template slot-scope="scope">
                        <span class="pl-1 pr-3 py-1 f-14 text-center">
                            <i :class="`${iconTipo(scope.row.tipo)} f-16`" />
                            {{ textTipo(scope.row.tipo) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="cant_viviendas" label="Viviendas" width="200" />
                <el-table-column>
                    <template slot-scope="scope">
                        <div class="col-1 ml-auto">
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false"
                                        popper-class="editar-residente br-4"
                            >
                                <div slot="content" class="tooltip-editar-residente">
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4"
                                         @click="actualizarNombre(scope.row)"
                                    >
                                        <p class="f-15">Cambiar nombre</p>
                                    </div>
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4"
                                         @click="actualizarPrivacidad(scope.row)"
                                    >
                                        <p class="f-15">Privacidad</p>
                                    </div>
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4"
                                         @click="eliminarCarpeta(scope.row.id)"
                                    >
                                        <p class="f-15">Eliminar</p>
                                    </div>
                                </div>
                                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                                    <i class="icon-vertical-points" />
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">
                            No hemos encontrado coincidencias con tu
                            búsqueda
                        </p>
                    </div>
                </div>
            </el-table>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        carpetas: Array,
        search: String
    },
    data(){
        return {

        }
    },
    methods: {
        iconTipo(tipo){
            switch (tipo){
            case 1:
                return 'icon-lock'
            case 2:
                return 'icon-group-outline'
            case 3:
                return 'icon-account-plus'
            }
        },
        textTipo(tipo){
            switch (tipo){
            case 1:
                return 'Privada'
            case 2:
                return 'Pública'
            case 3:
                return 'Compartida'
            }
        },
        eliminarCarpeta(item){
            this.$emit('eliminar', item)
        },
        actualizarNombre(item){
            this.$emit('actualizar', item)
        },
        actualizarPrivacidad(item){
            this.$emit('privacidad', item)
        },
        goDetail(item){
            this.$router.push({ name: 'biblioteca.carpetas.archivos', params: { idCarpeta: item.id } })
        }
    }
}
</script>

<style lang="scss" scoped>
.card-vigilante {
	width: 281px;
	height: 82px;
	border-radius: 4px;
	border: 1px solid #DBDBDB;
	background-color: #FCFCFC;

	&:hover {
		box-shadow: 0px 3px 6px #00000029;
	}
}

.card-ventana {
	width: 330px;
	height: 90px;
	display: flex;
	border-radius: 8px;
	border: 1px solid #DBDBDB;
}
</style>